import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
import Blocks from "../components/Blocks"
import HeaderStrip from "../components/HeaderStrip"

const PageTemplate = ({ data }) => {
  const page = data && data.page
  const { id } = page

  return (
    <>
      <Seo
        metaTitle={page.seo?.metaTitle || page.title}
        shareTitle={page.seo?.shareTitle || page.title}
        metaDesc={page.seo?.metaDesc}
        shareDesc={page.seo?.shareDesc}
        shareGraphic={page.seo?.shareGraphic?.asset.url}
      />

      <HeaderStrip strip={page.strip} />
      <section className="wrapper">
        <Blocks
          blocks={page.Blocks}
          _rawBlocks={page._rawBlocks}
          programId={id}
        />
      </section>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query programQuery($_id: String!) {
    page: sanityPrograms(_id: { eq: $_id }) {
      id
      title
      programLogo {
        asset {
          gatsbyImageData
        }
      }
      strip {
        stripColour
        stripLogo {
          asset {
            gatsbyImageData
          }
        }
        showStrip
      }
      _rawBlocks(resolveReferences: { maxDepth: 10 })
      Blocks {
        _key
        _type
        _rawContent(resolveReferences: { maxDepth: 10 })
        content {
          ...blocks
        }
        blockSettings {
          width
        }
      }
      seo {
        ...seoFields
      }
    }
  }
`
